import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../Config/Api";
import { logDOM } from "@testing-library/react";

export const fetchFlights = createAsyncThunk(
  "flights/fetchFlights",
  async (postData) => {
    try {
      const results = await Api("flightSearch", "POST", postData);
      return results.data;
    } catch (error) {
      console.log(error.message);
      return [];
    }
  }
);

export const fetchFlightsIndigo = createAsyncThunk(
  "flights/fetchFlightsIndigo",
  async (postData) => {
    const postBody = { ...postData, Supplier: "INDIGO" };
    try {
      const results = await Api("flightSearch", "POST", postBody);
      return results.data;
    } catch (error) {
      console.log(error.message);
      return [];
    }
  }
);

export const fetchFlightsTBO = createAsyncThunk(
  "flights/fetchFlightsTBO",
  async (postData) => {
    const postBody = { ...postData, Supplier: "TBO" };
    try {
      const results = await Api("flightSearch", "POST", postBody);
      return results.data;
    } catch (error) {
      console.log(error.message);
      return [];
    }
  }
);

export const fetchFlightsTravelport = createAsyncThunk(
  "flights/fetchFlightsTravelport",
  async (postData) => {
    const postBody = { ...postData, Supplier: "TRAVELPORT" };
    try {
      const results = await Api("flightSearch", "POST", postBody);
      return results.data;
    } catch (error) {
      console.log(error.message);
      return [];
    }
  }
);

const initFilters = {
  onwards: {
    time_range: [],
    airlines: [],
    stops: [],
    refund: [],
  },
  return: {
    time_range: [],
    airlines: [],
    stops: [],
    refund: [],
  },
};

const countObj = {
  time_range: {
    morning: 0,
    afternoon: 0,
    evening: 0,
    night: 0,
  },
  airlines: {},
  stops: {
    direct: 0,
    one_stop: 0,
    multi_stop: 0,
  },
  refund: {
    refundable: 0,
    non_refundable: 0,
  },
};

const initCounts = {
  onwards: countObj,
  return: countObj,
};

const initialState = {
  searchIndex: null,
  flights: [],
  returnFlights: [],
  paginatedFlights: [],
  paginatedReturnFlights: [],
  originalFlightsData: [],
  originalReturnFlightsData: [],
  calendarFare: [],
  selectedFlights: {
    departure: null,
    return: null,
  },
  flightFilters: initFilters,
  filtersCount: initCounts,
  status: "loading", // idle | loading | succeeded | failed
  error: null,
  searchString: null,
  timer: 0,
  isFiltersLoading: true,
  ReactNative: false,
  ReactNativeUserId: null,
  loaderCount: 0,
  international: false,
  fetchedTBO: false,
  fetchedTravelport: false,
  page: 1,
  returnPage: 1,
  perPage: 10,
};

const flightSlice = createSlice({
  name: "flights",
  initialState,
  reducers: {
    reduceTimer: (state, action) => {
      state.timer = state.timer - 1000;
    },
    setSearchString: (state, action) => {
      state.searchString = action.payload;
      if (
        state.searchString?.origin_country !==
        state.searchString?.destination_country
      ) {
        state.international = true;
      } else {
        state.international = false;
      }
    },
    setFiltersCount: (state, action) => {
      if (action.payload.flights.length) {
        let fCount = {
          time_range: {
            morning: 0,
            afternoon: 0,
            evening: 0,
            night: 0,
          },
          airlines: {},
          stops: {
            direct: 0,
            one_stop: 0,
            multi_stop: 0,
          },
          refund: {
            refundable: 0,
            non_refundable: 0,
          },
        };

        // eslint-disable-next-line
        action.payload.flights.map((flight) => {
          // console.log('single flight d', flight);
          const seg_length = flight?.Segments[0]?.length;
          const refundable = flight?.FareList[0]?.IsRefundable;

          if (flight?.Segments[0][0]?.Origin?.DepartTime) {
            const departTime = new Date(
              flight?.Segments[0][0]?.Origin?.DepartTime
            );
            const deptHr = departTime.getHours();

            if (deptHr >= 0 && deptHr < 12) {
              fCount.time_range.morning += 1;
            } else if (
              deptHr >= 12 &&
              deptHr < 16) {
              fCount.time_range.afternoon += 1;
            } else if (deptHr >= 16 &&
              deptHr < 21 ) {
              fCount.time_range.evening += 1;
            } else {
              fCount.time_range.night += 1;
            }
          }

          if (flight?.Segments[0][0]?.Airline?.AirlineName) {
            let airline_name =
              flight?.Segments[0][0]?.Airline?.AirlineName.toLowerCase()
                .trim()
                .replace(/ /g, "_");
            if (fCount.airlines[airline_name]) {
              fCount.airlines[airline_name] += 1;
            } else {
              fCount.airlines[airline_name] = 1;
            }
          }
          // console.log('seg_length', seg_length);
          if (seg_length === 1) {
            fCount.stops.direct += 1;
          } else if (seg_length === 2) {
            fCount.stops.one_stop += 1;
          } else if (seg_length > 2) {
            fCount.stops.multi_stop += 1;
          }

          if (refundable) {
            fCount.refund.refundable += 1;
          } else {
            fCount.refund.non_refundable += 1;
          }
        });
        if (action.payload.type === "onwards") {
          state.filtersCount.onwards = fCount;
        } else {
          state.filtersCount.return = fCount;
        }
      }
    },
    setInternationalReturnFiltersCount: (state, action) => {
      if (action.payload.flights.length) {
        let fCount = {
          time_range: {
            morning: 0,
            afternoon: 0,
            evening: 0,
            night: 0,
          },
          airlines: {},
          stops: {
            direct: 0,
            one_stop: 0,
            multi_stop: 0,
          },
          refund: {
            refundable: 0,
            non_refundable: 0,
          },
        };

        // eslint-disable-next-line
        action.payload.flights.map((flight) => {
          // console.log('single flight d', flight);
          const seg_length = flight?.Segments[1]?.length;
          // const refundable = flight?.FareList[1]?.IsRefundable;

          if (Boolean(flight?.Segments[1])) {
            const departTime = new Date(
              flight?.Segments[1][0]?.Origin?.DepartTime
            );
            const deptHr = departTime.getHours();
            // if (deptHr < 12) {
            //   fCount.time_range.morning += 1;
            // } else if (deptHr < 18) {
            //   fCount.time_range.afternoon += 1;
            // } else if (deptHr < 21) {
            //   fCount.time_range.evening += 1;
            // } else {
            //   fCount.time_range.night += 1;
            // }
            if (deptHr >= 0 && deptHr < 12) {
              fCount.time_range.morning += 1;
            } else if (
              deptHr >= 12 &&
              deptHr < 16) {
              fCount.time_range.afternoon += 1;
            } else if (deptHr >= 16 &&
              deptHr < 21 ) {
              fCount.time_range.evening += 1;
            } else {
              fCount.time_range.night += 1;
            }




          }

          if (seg_length === 1) {
            fCount.stops.direct += 1;
          } else if (seg_length === 2) {
            fCount.stops.one_stop += 1;
          } else if (seg_length > 2) {
            fCount.stops.multi_stop += 1;
          }

          // if (refundable) {
          //   fCount.refund.refundable += 1;
          // } else {
          //   fCount.refund.non_refundable += 1;
          // }
        });

        state.filtersCount.return = fCount;
      }
    },
    setSelectedFlights: (state, action) => {
      if (action.payload.type) {
        state.selectedFlights.return = action.payload.flight;
      } else {
        state.selectedFlights.departure = action.payload.flight;
      }
      // state.searchString = action.payload;
    },
    clearAllFilters: (state, action) => {
      state.flightFilters = initFilters;
      state.flights = state.originalFlightsData;
      state.returnFlights = state.originalReturnFlightsData;

      const temparr_onwards = state.originalFlightsData.slice(0, state.perPage);
      state.paginatedFlights = temparr_onwards;

      const temparr_return = state.originalReturnFlightsData.slice(
        0,
        state.perPage
      );
      state.paginatedReturnFlights = temparr_return;

      state.page = 1;
      state.returnPage = 1;

      if (state.flights.length) {
        state.selectedFlights.departure = state.flights[0];
      }

      if (state.returnFlights.length) {
        state.selectedFlights.return = state.returnFlights[0];
      }
    },
    filterFlightData: (state, action) => {
      // console.log("log from filterdata method", action.type);
      const flights =
        action.type === "onwards"
          ? state.originalFlightsData
          : state.originalReturnFlightsData;
      const filters =
        action.type === "onwards"
          ? state.flightFilters.onwards
          : state.flightFilters.return;
      // console.log("log from filters", current(filters));
      // console.log("unfiltered flights", current(flights));

      const filteredFlights = flights.filter((flight) => {
        const filter_check = {
          time_range: filters.time_range.length ? false : true,
          airlines: filters.airlines.length ? false : true,
          stops: filters.stops.length ? false : true,
          refund: filters.refund.length ? false : true,
        };
        const seg_length = flight?.Segments[0]?.length;
        const refundable = flight?.FareList[0]?.IsRefundable;

        if (filters.time_range.length > 0) {
          if (flight?.Segments[0][0]?.Origin?.DepartTime) {
            const departTime = new Date(
              flight?.Segments[0][0]?.Origin?.DepartTime
            );
            const deptHr = departTime.getHours();
               if (deptHr >= 0 && deptHr < 12 && filters.time_range.indexOf("morning") !== -1) 
            {
              filter_check.time_range = true;
            } else if (
              deptHr >= 12 &&
              deptHr < 16 &&
              filters.time_range.indexOf("afternoon") !== -1
            ) {
              filter_check.time_range = true;
            } else if (
              deptHr >= 16 &&
              deptHr < 21 &&
              filters.time_range.indexOf("evening") !== -1
            ) {
              filter_check.time_range = true;
            } else if (
              deptHr >= 21 &&
              filters.time_range.indexOf("night") !== -1
            )  {
              filter_check.time_range = true;
            }
          }
        }

        if (filters.airlines.length > 0) {
          if (flight?.Segments[0][0]?.Airline?.AirlineName) {
            let airline_name =
              flight?.Segments[0][0]?.Airline?.AirlineName.toLowerCase().trim();
            filter_check.airlines =
              filters.airlines.indexOf(airline_name) !== -1;
          }
        }

        if (filters.stops.length > 0) {
          if (seg_length === 1 && filters.stops.indexOf("direct") !== -1) {
            filter_check.stops = true;
          } else if (
            seg_length === 2 &&
            filters.stops.indexOf("one_stop") !== -1
          ) {
            filter_check.stops = true;
          } else if (
            seg_length > 2 &&
            filters.stops.indexOf("multi_stop") !== -1
          ) {
            filter_check.stops = true;
          }
        }

        if (filters.refund.length > 0) {
          if (refundable && filters.refund.indexOf("refundable") !== -1) {
            filter_check.refund = true;
          }
          if (!refundable && filters.refund.indexOf("non_refundable") !== -1) {
            filter_check.refund = true;
          }
        }

        const areTrue = Object.values(filter_check).every(
          (value) => value === true
        );

        return areTrue;
      });

      // console.log("filtered flights", filteredFlights);

      if (action.type === "onwards") {
        state.flights = filteredFlights;
        const temparr_onwards = filteredFlights.slice(0, state.perPage);
        state.paginatedFlights = temparr_onwards;
        state.page = 1;
      } else {
        state.returnFlights = filteredFlights;
        const temparr_return = filteredFlights.slice(0, state.perPage);
        state.paginatedReturnFlights = temparr_return;
        state.returnPage = 1;
      }

      if (state.flights.length) {
        state.selectedFlights.departure = state.flights[0];
      }

      if (state.returnFlights?.length) {
        state.selectedFlights.return = state.returnFlights[0];
      }
    },
    filterInternationalFlightData: (state, action) => {
      // console.log("log from filterdata method", action.type);
      const flights = state.originalFlightsData;
      const filters = state.flightFilters.onwards;
      const return_filters = state.flightFilters.return;
      // console.log("log from filters", current(filters));
      // console.log("unfiltered flights", current(flights));

      const filteredFlights = flights.filter((flight) => {
        const filter_check = {
          time_range: filters.time_range.length ? false : true,
          airlines: filters.airlines.length ? false : true,
          stops: filters.stops.length ? false : true,
          refund: filters.refund.length ? false : true,
          return_time_range: return_filters.time_range.length ? false : true,
          return_stops: return_filters.stops.length ? false : true,
        };

        const seg_length = flight?.Segments[0]?.length;
        const refundable = flight?.FareList[0]?.IsRefundable;

        const return_seg_length = flight?.Segments[1]?.length;
        // console.log('return_filters.stops', current(return_filters.stops));
        // console.log('return_seg_length', return_seg_length);

        if (filters.time_range.length > 0) {
          if (flight?.Segments[0][0]?.Origin?.DepartTime) {
            const departTime = new Date(
              flight?.Segments[0][0]?.Origin?.DepartTime
            );
            const deptHr = departTime.getHours();
               if (deptHr >= 0 && deptHr < 12 && filters.time_range.indexOf("morning") !== -1) 
            {
              filter_check.time_range = true;
            } else if (
              deptHr >= 12 &&
              deptHr < 16 &&
              filters.time_range.indexOf("afternoon") !== -1
            ) {
              filter_check.time_range = true;
            } else if (
              deptHr >= 16 &&
              deptHr < 21 &&
              filters.time_range.indexOf("evening") !== -1
            ) {
              filter_check.time_range = true;
            } else if (
              deptHr >= 21 &&
              filters.time_range.indexOf("night") !== -1
            )  {
              filter_check.time_range = true;
            }
          }
        }

        if (filters.airlines.length > 0) {
          if (flight?.Segments[0][0]?.Airline?.AirlineName) {
            let airline_name =
              flight?.Segments[0][0]?.Airline?.AirlineName.toLowerCase().trim();
            filter_check.airlines =
              filters.airlines.indexOf(airline_name) !== -1;
          }
        }

        if (filters.stops.length > 0) {
          if (seg_length === 1 && filters.stops.indexOf("direct") !== -1) {
            filter_check.stops = true;
          } else if (
            seg_length === 2 &&
            filters.stops.indexOf("one_stop") !== -1
          ) {
            filter_check.stops = true;
          } else if (
            seg_length > 2 &&
            filters.stops.indexOf("multi_stop") !== -1
          ) {
            filter_check.stops = true;
          }
        }

        if (filters.refund.length > 0) {
          if (refundable && filters.refund.indexOf("refundable") !== -1) {
            filter_check.refund = true;
          }
          if (!refundable && filters.refund.indexOf("non_refundable") !== -1) {
            filter_check.refund = true;
          }
        }

        if (return_filters.time_range.length > 0) {
          if (Boolean(flight?.Segments[1])) {
            const returnDepartTime = new Date(
              flight?.Segments[1][0]?.Origin?.DepartTime
            );
            const returnDeptHr = returnDepartTime.getHours();

            if (
              returnDeptHr < 12 &&
              return_filters.time_range.indexOf("morning") !== -1
            ) {
              filter_check.return_time_range = true;
            } else if (
              returnDeptHr >= 12 &&
              returnDeptHr < 18 &&
              return_filters.time_range.indexOf("afternoon") !== -1
            ) {
              filter_check.return_time_range = true;
            } else if (
              returnDeptHr >= 18 &&
              returnDeptHr < 21 &&
              return_filters.time_range.indexOf("evening") !== -1
            ) {
              filter_check.return_time_range = true;
            } else if (
              returnDeptHr >= 21 &&
              return_filters.time_range.indexOf("night") !== -1
            ) {
              filter_check.return_time_range = true;
            }
          }
        }

        if (return_filters.stops.length > 0) {
          if (
            return_seg_length === 1 &&
            return_filters.stops.indexOf("direct") !== -1
          ) {
            filter_check.return_stops = true;
          } else if (
            return_seg_length === 2 &&
            return_filters.stops.indexOf("one_stop") !== -1
          ) {
            filter_check.return_stops = true;
          } else if (
            return_seg_length > 2 &&
            return_filters.stops.indexOf("multi_stop") !== -1
          ) {
            filter_check.return_stops = true;
          }
        }

        const areTrue = Object.values(filter_check).every(
          (value) => value === true
        );

        return areTrue;
      });
      // console.log('flights', current(flights));
      // console.log("filtered flights", filteredFlights);

      state.flights = filteredFlights;
      const temparr_onwards = filteredFlights.slice(0, state.perPage);
      state.paginatedFlights = temparr_onwards;
      state.page = 1;
    },
    setFlightFilters: (state, action) => {
      // console.log("flight filters", action.payload);

      const flightType =
        action.payload.type === "onwards"
          ? state.flightFilters.onwards
          : state.flightFilters.return;

      const value = action.payload.value.toLowerCase();
      const index = flightType[action.payload.ftype].indexOf(value);
      index !== -1
        ? flightType[action.payload.ftype].splice(index, 1)
        : flightType[action.payload.ftype].push(value);

      state.flightFilters = { ...state.flightFilters };
      // if (action.payload.type === "onwards") {

      // } else {
      //   state.flightFilters = { ...state.flightFilters, flightType };
      // }

      if (
        state.searchString.journeyType === "Round Trip" &&
        state.international
      ) {
        flightSlice.caseReducers.filterInternationalFlightData(state, {
          type: action.payload.type,
        });
      } else {
        flightSlice.caseReducers.filterFlightData(state, {
          type: action.payload.type,
        });
      }
      // state.searchString = action.payload;
    },
    resetFlights: (state, action) => {
      state.status = "loading";
      state.flightFilters = initFilters;
      state.filtersCount = initCounts;
      state.flights = [];
      state.returnFlights = [];
      state.timer = 0;
      state.selectedFlights = {
        departure: null,
        return: null,
      };
      state.loaderCount = 0;
      state.fetchedTBO = false;
      state.fetchedTravelport = false;
      state.paginatedFlights = [];
      state.paginatedReturnFlights = [];
      state.page = 1;
      state.returnPage = 1;
    },

    SetReactNativeData: (state, action) => {
      state.ReactNativeUserId = action?.payload;
      state.ReactNative = true;
    },

    setPaginatedFlights: (state, action) => {
      state.paginatedFlights = action?.payload?.paginatedFlights;
      state.page = action?.payload?.page;
    },

    setPaginatedReturnFlights: (state, action) => {
      state.paginatedReturnFlights = action?.payload?.paginatedReturnFlights;
      state.returnPage = action?.payload?.page;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFlights.pending, (state, action) => {
        state.status = "loading";
        state.isFiltersLoading = true;
        // state.flightFilters = initFilters;
        // state.filtersCount = initCounts;
        // state.flights = [];
        // state.returnFlights = [];
        // state.timer = 0;
      })
      .addCase(fetchFlights.fulfilled, (state, action) => {
        state.isFiltersLoading = false;
        state.loaderCount += 1;
        if (action.payload.data?.Result) {
          // console.log('fetchFlights',action.payload.data.Result[0]);
          var flights = action.payload.data.Result[0].map((fl) => {
            fl.searchTokenId = action.payload.data.SearchTokenId;
            return fl;
          });

          // state.flights = state.flights.concat(flights);
        }
        if (action.payload.data?.Result && action.payload.data?.Result[1]) {
          var returnFlights = action.payload.data.Result[1].map((fl) => {
            fl.searchTokenId = action.payload.data.SearchTokenId;
            return fl;
          });
          // state.returnFlights = state.returnFlights.concat(returnFlights);
        }
        state.originalFlightsData = action.payload.data?.Result
          ? action.payload.data.Result[0]
          : [];

        if (
          (state.fetchedTBO && state.fetchedTravelport) ||
          state.originalFlightsData.length === 0
        ) {
          state.status = "succeeded";
        }
        state.originalReturnFlightsData =
          action.payload.data?.Result && action.payload.data?.Result[1]
            ? action.payload.data.Result[1]
            : [];
        state.calendarFare = action.payload?.calendarFare?.Result
          ? action.payload?.calendarFare?.Result
          : [];
        // state.searchIndex = action.payload.data?.SearchTokenId;
        if (!Boolean(state.timer)) {
          state.timer = 10 * 60 * 1000;
        }

        if (
          action.payload.data?.Result &&
          action.payload.data?.Result[0] &&
          !Boolean(state.selectedFlights.departure)
        ) {
          state.selectedFlights.departure = flights[0];
        }

        if (
          action.payload.data?.Result &&
          action.payload.data?.Result[1] &&
          !Boolean(state.selectedFlights.return)
        ) {
          state.selectedFlights.return = returnFlights[0];
        }
      })
      .addCase(fetchFlights.rejected, (state, action) => {
        state.status = "succeeded";
        state.flightFilters = initFilters;
        state.flights = [];
        state.returnFlights = [];
        state.isFiltersLoading = false;
        state.loaderCount += 1;

        // state.error = action.error.message;
      })
      // .addCase(fetchFlightsIndigo.pending, (state, action) => {
      //   state.status = "loading";
      // })
      // .addCase(fetchFlightsIndigo.fulfilled, (state, action) => {
      //   state.status = "succeeded";

      //   if (!Boolean(state.timer)) {
      //     state.timer = 10 * 60 * 1000;
      //   }

      //   if (action.payload.data?.Result) {
      //     // const flights = [...state.flights, action.payload.data.Result[0]];
      //     // console.log('fetchFlightsIndigo',action.payload.data.Result[0]);
      //     const flights = action.payload.data.Result[0].map((fl) => {
      //       fl.searchTokenId = action.payload.data.SearchTokenId;
      //       return fl;
      //     });
      //     state.flights = state.flights.concat(flights);
      //   }

      //   if (action.payload.data?.Result && action.payload.data?.Result[1]) {
      //     const returnFlights = action.payload.data.Result[1].map((fl) => {
      //       fl.searchTokenId = action.payload.data.SearchTokenId;
      //       return fl;
      //     });
      //     state.returnFlights = state.returnFlights.concat(returnFlights);
      //   }

      //   if (
      //     action.payload.data?.Result &&
      //     action.payload.data?.Result[0] &&
      //     !Boolean(state.selectedFlights.departure)
      //   ) {
      //     state.selectedFlights.departure = action.payload.data.Result[0][0];
      //   }

      //   if (
      //     action.payload.data?.Result &&
      //     action.payload.data?.Result[1] &&
      //     !Boolean(state.selectedFlights.return)
      //   ) {
      //     state.selectedFlights.return = action.payload.data.Result[1][0];
      //   }
      // })
      // .addCase(fetchFlightsIndigo.rejected, (state, action) => {
      //   state.status = "succeeded";
      //   state.loaderCount += 1;
      // })
      .addCase(fetchFlightsTBO.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchFlightsTBO.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload.data?.Result && action.payload.data?.Result[1]) {
          var returnFlights = action.payload.data.Result[1].map((fl) => {
            fl.searchTokenId = action.payload.data.SearchTokenId;
            return fl;
          });
          // state.returnFlights = state.returnFlights.concat(returnFlights);
        }

        state.fetchedTBO = true;
        state.loaderCount += 1;

        if (!Boolean(state.timer)) {
          state.timer = 10 * 60 * 1000;
        }

        if (action.payload.data?.Result) {
          // console.log('fetchFlightsTBO',action.payload.data.Result[0]);
          const flights = action.payload.data.Result[0].map((fl) => {
            fl.searchTokenId = action.payload.data.SearchTokenId;
            fl.priceforsort = fl?.FareList[0]?.PublishedPrice;
            return fl;
          });

          state.originalFlightsData = action.payload.data?.Result
            ? action.payload.data.Result[0]
            : [];
          state.originalReturnFlightsData =
            action.payload.data?.Result && action.payload.data?.Result[1]
              ? action.payload.data.Result[1]
              : [];

          if (
            action.payload.data?.Result &&
            action.payload.data?.Result[0] &&
            !Boolean(state.selectedFlights.departure)
          ) {
            state.selectedFlights.departure = flights[0];
          }

          if (
            action.payload.data?.Result &&
            action.payload.data?.Result[1] &&
            !Boolean(state.selectedFlights.return)
          ) {
            state.selectedFlights.return = returnFlights[0];
          }

          if (flights?.length) {
            state.status = "succeeded";
            let concatflights = state.flights.concat(flights);
            let sortedflights = concatflights.sort(
              (a, b) => a.priceforsort - b.priceforsort
            );
            state.flights = sortedflights;

            // console.log('state.flights', state.flights);
            // console.log('flights', flights);

            if (state.paginatedFlights.length < state.perPage) {
              // console.log(state.paginatedFlights.length, state.perPage);
              const perPage = state.perPage - state.paginatedFlights.length;
              let temparr_onwards;
              if (state.paginatedFlights.length <= 10) {
                temparr_onwards = state.flights;
              } else {
                temparr_onwards = state.flights.slice(0, perPage);
              }
              state.paginatedFlights = temparr_onwards;
            }
          }
        }

        if (action.payload.data?.Result && action.payload.data?.Result[1]) {
          const returnFlights = action.payload.data.Result[1].map((fl) => {
            fl.searchTokenId = action.payload.data.SearchTokenId;
            return fl;
          });
          state.returnFlights = state.returnFlights.concat(returnFlights);
          if (state.paginatedReturnFlights.length < state.perPage) {
            const perPage = state.perPage - state.paginatedReturnFlights.length;
            const temparr_return = returnFlights.slice(0, perPage);
            state.paginatedReturnFlights = temparr_return;
          }
        }

        if (
          action.payload.data?.Result &&
          action.payload.data?.Result[0] &&
          !Boolean(state.selectedFlights.departure)
        ) {
          state.selectedFlights.departure = action.payload.data.Result[0][0];
        }

        if (
          action.payload.data?.Result &&
          action.payload.data?.Result[1] &&
          !Boolean(state.selectedFlights.return)
        ) {
          state.selectedFlights.return = action.payload.data.Result[1][0];
        }
      })
      .addCase(fetchFlightsTBO.rejected, (state, action) => {
        state.status = "loading";
        state.fetchedTBO = true;
        state.loaderCount += 1;
        // state.error = action.error.message;
      })
      .addCase(fetchFlightsTravelport.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchFlightsTravelport.fulfilled, (state, action) => {
        state.fetchedTravelport = true;
        if (!Boolean(state.timer)) {
          state.timer = 10 * 60 * 1000;
        }

        if (action.payload.data?.Result) {
          // console.log("fetchFlightsTravelport", action.payload.data.Result[0]);
          const flights = action.payload.data.Result[0].map((fl) => {
            fl.searchTokenId = action.payload.data.SearchTokenId;
            fl.priceforsort = fl?.FareList[0]?.PublishedPrice;
            return fl;
          });

          if (flights?.length) {
            state.status = "succeeded";
            let concatflights = state.flights.concat(flights);
            let sortedflights = concatflights.sort(
              (a, b) => a.priceforsort - b.priceforsort
            );
            state.flights = sortedflights;
            if (state.paginatedFlights.length < state.perPage) {
              const perPage = state.perPage - state.paginatedFlights.length;
              let temparr_onwards;
              if (state.paginatedFlights.length <= 10) {
                temparr_onwards = state.flights;
              } else {
                temparr_onwards = state.flights.slice(0, perPage);
              }
              state.paginatedFlights = temparr_onwards;
            }
          }
        }

        if (action.payload.data?.Result && action.payload.data?.Result[1]) {
          const returnFlights = action.payload.data.Result[1].map((fl) => {
            fl.searchTokenId = action.payload.data.SearchTokenId;
            return fl;
          });
          state.returnFlights = state.returnFlights.concat(returnFlights);
          if (state.paginatedReturnFlights.length < state.perPage) {
            const perPage = state.perPage - state.paginatedReturnFlights.length;
            const temparr_return = returnFlights.slice(0, perPage);
            state.paginatedReturnFlights = temparr_return;
          }
        }

        if (action.payload.data?.Result && action.payload.data?.Result[0]) {
          state.selectedFlights.departure = action.payload.data.Result[0][0];
        }

        if (action.payload.data?.Result && action.payload.data?.Result[1]) {
          state.selectedFlights.return = action.payload.data.Result[1][0];
        }
      })
      .addCase(fetchFlightsTravelport.rejected, (state, action) => {
        state.fetchedTravelport = true;
        // state.status = "succeeded";
        // state.error = action.error.message;
      });
  },
});

export const selectAllFlights = (state) => state.flights.flights;
export const selectAllReturnFlights = (state) => state.flights.returnFlights;
export const getCalendarFare = (state) => state.flights.calendarFare;
export const getSelectedFlights = (state) => state.flights.selectedFlights;
export const getFlightsData = (state) => state.flights.originalFlightsData;
export const getFlightFilters = (state) => state.flights.flightFilters;
export const getAppUserId = (state) => state.flights.ReactNativeUserId;
export const getReactNativeApp = (state) => state.flights.ReactNative;
export const getFlightFiltersLoading = (state) =>
  state.flights.isFiltersLoading;
export const getReturnFlightsData = (state) =>
  state.flights.originalReturnFlightsData;
export const getStatus = (state) => state.flights.status;
export const getFiltersCount = (state) => state.flights.filtersCount;
export const searchIndex = (state) => state.flights.searchIndex;
export const searchString = (state) => state.flights.searchString;
export const loaderCount = (state) => state.flights.loaderCount;
export const timer = (state) => state.flights.timer;
export const international = (state) => state.flights.international;
export const paginatedFlights = (state) => state.flights.paginatedFlights;
export const paginatedReturnFlights = (state) =>
  state.flights.paginatedReturnFlights;
export const getPage = (state) => state.flights.page;
export const getReturnPage = (state) => state.flights.returnPage;
export const getPerPage = (state) => state.flights.perPage;

export const {
  reduceTimer,
  setSearchString,
  setSelectedFlights,
  setFlightFilters,
  clearAllFilters,
  setFiltersCount,
  setInternationalReturnFiltersCount,
  resetFlights,
  SetReactNativeData,
  setPaginatedFlights,
  setPaginatedReturnFlights,
} = flightSlice.actions;

export default flightSlice.reducer;
