import React, { useEffect, useState } from "react";
import FollowRequestMemberCard from "./Components/FollowRequestMemberCard";
import Api from "../../../Config/Api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import NotFound from "../../Elements/NotFound";

const FollowRequests = () => {
  const [imageUrl, setImageUrl] = useState("");
  const [requestList, setRequestList] = useState([]);
  const userId = useSelector((state) => state.authentication.userId);

  const fetchFollowRequests = async () => {
    const body = { login_member_id: userId };
    const res = await Api("follow_request_list", "POST", body);
    if (res?.data?.status === 1) {
      setRequestList(res?.data?.data);
    } else {
      toast.error(res?.data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const handleUser = async (d, status) => {
    
    const body = {
      login_member_id: userId,
      following_member_id: d?.id,
      status,
    };
    const res = await Api("followMember", "POST", body);
    if (res?.data?.status === 1) {
      fetchFollowRequests();
    } else {
      toast.error(res?.data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchFollowRequests();
  }, []);

  return (
    <div className="p-3 p-lg-3 bg-white rounded-4 shadow">
      <div className="mb-3">
        <div className="row g-3">
          <div className="col-md-8">
            <h4 className="text-dark mb-2">Follow Requests</h4>
          </div>
        </div>
      </div>
      <div className="">
      <div className="row g-3">
  {requestList?.length > 0 ? (
    requestList.map((data) => (
      <div className="col-sm-6 col-lg-4" key={data.MemberSelf.id}>
        <div className="">
          <FollowRequestMemberCard
            data={data.MemberSelf}
            handleUser={handleUser}
          />
        </div>
      </div>
    ))
  ) : (
    <NotFound title="You have no follow requests right now..." />
  )}
</div>

      </div>
    </div>
  );
};

export default FollowRequests;
