import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import Api from "../Config/Api";

//fetchUserDetail
export const fetchUserDetail = createAsyncThunk(
  "notification/fetchUserDetail",
  async (postData) => {
    try {
      const results = await Api("getUserDetails", "POST", postData);
      return results.data.memberInfo;
    } catch (error) {
      console.log(error.message);
    }
  }
);
//fetchUserDetail

const initialState = {
  user: Cookies.get("UserCookie") ? Cookies.get("UserCookie") : null,
  userId: Cookies.get("UserId") ? Cookies.get("UserId") : null,
  isUser: Cookies.get("UserCookie") ? true : false,
  userDetailStatus: "loading",
  error: null,
};

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setUser: (state, action) => {
      // console.log("setUser", action.payload);
      const userData = JSON.stringify(action.payload);
      if (userData) {
        Cookies.set("UserCookie", userData, {
          expires: 1 / 24,
          path: "/",
          //httpOnly:true
        });
        Cookies.set("UserId", action.payload.member_id, {
          expires: 1 / 24,
          path: "/",
          //httpOnly:true
        });

        state.user = userData;
        state.userId = action.payload.member_id;
        state.isUser = action.payload ? true : false;
      }
    },
    removeUser: (state) => {
      Cookies.remove("UserCookie", {
        expires: new Date("May 16, 1970"),
        path: "/",
      });
      Cookies.remove("UserId", {
        expires: new Date("May 16, 1970"),
        path: "/",
      });
      state.user = null;
      state.userId = null;
      state.isUser = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserDetail.pending, (state) => {
        state.userDetailStatus = "loading";
      })
      .addCase(fetchUserDetail.fulfilled, (state, action) => {
        state.userDetailStatus = "succeeded";
        if (!!action.payload && !!Object.keys(action.payload)) {
          authenticationSlice.caseReducers.setUser(state, action);
        } else {
          authenticationSlice.caseReducers.removeUser(state);
        }
      })
      .addCase(fetchUserDetail.rejected, (state) => {
        state.userDetailStatus = "succeeded";
      });
  },
});

// Action creators are generated for each case reducer function
export const { setUser, removeUser } = authenticationSlice.actions;
export default authenticationSlice.reducer;
