import { createSlice } from "@reduxjs/toolkit";

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    isLoginShow: false,
    isQRLoginShow: false,
    isSignupShow: false,
    signupData: "",
    isForgotShow: false,
    isResetShow: false,
    isVerifyNumberShow: false,
  },
  reducers: {
    toggleLogin: (state, action) => {
      state.isLoginShow = action.payload;
    },
    toggleQRLogin: (state, action) => {
      console.log('toggleQRLogin',action.payload);
      state.isQRLoginShow = action.payload;
    },
    toggleSignup: (state, action) => {
      state.isSignupShow = action.payload;
    },
    setSignupData: (state, action) => {
      state.signupData = action.payload;
    },
    toggleForgot: (state, action) => {
      state.isForgotShow = action.payload;
    },
    toggleReset: (state, action) => {
      state.isResetShow = action.payload;
    },
    toggleVerifyNumber: (state, action) => {
      state.isVerifyNumberShow = action.payload;
    },
  },
});
export const {
  toggleLogin,
  toggleQRLogin,
  toggleSignup,
  setSignupData,
  toggleForgot,
  toggleReset,
  toggleVerifyNumber,
} = loginSlice.actions;
export default loginSlice.reducer;
