import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  scale: 1,
  isDownloadAppShow: true,
};

export const widgetSlice = createSlice({
  name: "widget",
  initialState,
  reducers: {
    zoomPlus: (state) => {
      if (state.scale <= 1.3) {
        state.scale = state.scale + 0.1;
      }
    },
    zoomMinus: (state) => {
      if (state.scale >= 0.8) {
        state.scale = state.scale - 0.1;
      }
    },
    zoomEqual: (state) => {
      state.scale = 1;
    },
    closeDownloadApp: (state) => {
      state.isDownloadAppShow = false;
    },
  },
});

export const { zoomPlus, zoomMinus, zoomEqual, closeDownloadApp } = widgetSlice.actions;
export default widgetSlice.reducer;
