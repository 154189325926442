import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../Config/Api";

export const fetchHotels = createAsyncThunk(
  "hotels/fetchHotels",
  async (postData) => {
    try {
        const results = await Api("hotelresults", "POST", postData);
       return results.data;
    } catch (error) {
        console.log(error.message);
    }
   
  }
);

const initFilters = {
  search: '',
  rating: [],
  price_range: []
}

const initialState = {
  searchIndex: null,
  hotels: [],
  status: "loading", // idle | loading | succeeded | failed
  error: null,
  timer: 0,
  params: null,
  TraceId:null,
  checkOutDetails:[],
  originalHotelsData: [],
  originalFilterHotelsData: [],
  hotelFilters: initFilters
};

const hotelSlice = createSlice({
  name: "hotels",
  initialState,
  reducers: {
    setHotelParameters: (state, action) => {
        state.params = action.payload
    },
    setCheckOutDetails:(state, action)=>{
      state.checkOutDetails = action.payload
    },
    clearAllFilters: (state, action) => {
      state.hotelFilters = initFilters;
      state.hotels = state.originalHotelsData;
    },
    filterHotelData: (state, action) => {
      // console.log("log from filterdata method", action.type);
      const hotels = state.originalHotelsData;
      const filters = state.hotelFilters;
      const filteredHotels = hotels.filter((hotel) => {
        const filter_check = {
          search: filters.search.length ? false : true,
          rating: filters.rating.length ? false : true,
          price_range: filters.price_range.length ? false : true
        };

        if (filters.search.length > 0) {
          filter_check.search = hotel.HotelName.toLowerCase().indexOf(filters.search) !== -1
        }

        if (filters.rating.length > 0) {
          filter_check.rating = filters.rating.indexOf(hotel.StarRating) !== -1
        }


        if (filters.price_range.length > 0) {
                 if(filters.price_range.indexOf('1000') !== -1 && hotel?.Price?.PublishedPrice <= 1000){
            filter_check.price_range = true;
          }


          if(filters.price_range.indexOf('1000-12000') !== -1 && hotel?.Price?.PublishedPrice > 1000 && hotel?.Price?.PublishedPrice <= 12000){
            filter_check.price_range = true;
          }

          if(filters.price_range.indexOf('12001-23000') !== -1 && hotel?.Price?.PublishedPrice > 12000 && hotel?.Price?.PublishedPrice <= 23000){
            filter_check.price_range = true;
          }

          if(filters.price_range.indexOf('23001-34000') !== -1 && hotel?.Price?.PublishedPrice > 23000 && hotel?.Price?.PublishedPrice <= 34000){
            filter_check.price_range = true;
          }
          
          // filter_check.rating = filters.rating.indexOf(hotel.Price?.PublishedPrice) !== -1
          
          // if (refundable && filters.refund.indexOf("refundable") !== -1) {
          //   filter_check.refund = true;
          // }
          // if (!refundable && filters.refund.indexOf("non_refundable") !== -1) {
          //   filter_check.refund = true;
          // }
        }

        const areTrue = Object.values(filter_check).every(
          (value) => value === true
        );
        
        return areTrue;
      });

      // console.log("filtered flights", filteredFlights);

      state.hotels = filteredHotels;

     
    },
    setHotelFilters: (state, action) => {
      const hotelFilters = state.hotelFilters;

      const value = typeof(action.payload.value) !== "number" ?  action.payload.value.toLowerCase() :  action.payload.value;
      if(action.payload.ftype !== 'search'){
        const index = hotelFilters[action.payload.ftype].indexOf(value);
        index !== -1
          ? hotelFilters[action.payload.ftype].splice(index, 1)
          : hotelFilters[action.payload.ftype].push(value);
      }
      else{
        hotelFilters[action.payload.ftype] = value;
      }
      

      state.hotelFilters = { ...state.hotelFilters };
     

      hotelSlice.caseReducers.filterHotelData(state, action);
      // state.searchString = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchHotels.pending, (state, action) => {
        state.status = "loading";
        state.hotelFilters = initFilters;
      })
      .addCase(fetchHotels.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.hotels = action.payload.data?.HotelSearchResult?.HotelResults ? action.payload.data.HotelSearchResult.HotelResults : [];
        state.originalHotelsData = action.payload.data?.HotelSearchResult?.HotelResults ? action.payload.data.HotelSearchResult.HotelResults : [];
        state.originalFilterHotelsData = action.payload.data?.HotelSearchResult?.HotelResults ? action.payload.data.HotelSearchResult.HotelResults : [];
        state.searchIndex = action.payload.data?.SearchTokenId;
        state.TraceId = action.payload.data?.HotelSearchResult.TraceId;
        state.trip_id = action?.payload?.trip_id;
        state.timer = (10 * 60) * 1000;
        
        // console.log('ashish'+state);
      })
      .addCase(fetchHotels.rejected, (state, action) => {
        state.status = "succeeded";
        // state.error = action.error.message;
      
      });
  },
});


export const selectAllHotels = (state) => state.hotels.hotels;
export const getoriginalHotelsData = (state) => state.hotels.originalHotelsData;
export const originalFilterHotelsData = (state) => state.hotels.originalFilterHotelsData;
export const getStatus = (state) => state.hotels.status;
export const getFilters = (state) => state.hotels.hotelFilters;
export const searchIndex = (state) => state.hotels.searchIndex;
export const TraceId = (state) => state.hotels.TraceId;
export const trip_id = (state) => state.hotels.trip_id;
export const searchParameters = (state) => state.hotels.params;
export const hotelCheckoutDetails = (state) => state.hotels.checkOutDetails;
// export const timer = (state) => state.hotels.timer;
export const { setHotelParameters ,setCheckOutDetails, setHotelFilters,clearAllFilters} = hotelSlice.actions;
export default hotelSlice.reducer;
