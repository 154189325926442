import React, { useState } from "react";
import MemberIcon from "../../../../assets/images/user.webp";
import { Link } from "react-router-dom";

const FollowRequestMemberCard = ({ data, handleUser }) => {
  const [isloading1, setIsloading1] = useState(false);
  const [isloading2, setIsloading2] = useState(false);
  const setDefaultImage = (e, icon) => {
    e.target.src = icon;
  };

  return (
    <>
      <div className="membercardbox">
        <div className="membercardboximg">
          <img
            src={
              !!data?.image
                ? `${process.env.REACT_APP_VENDOR_NEW_IMAGE_URL}${data?.image}`
                : MemberIcon
            }
            alt={data?.contact_person}
            onError={(e) => setDefaultImage(e, MemberIcon)}
          />
        </div>
        <div className="membercardboxdata">
          <h3>
            <Link to={`/member/view_user_profile/${btoa(data?.id)}`}>
              {data?.contact_person}
            </Link>
          </h3>
          <p>{data?.gender}</p>
          <div className="d-flex gap-2 justify-content-center">
            <button
              onClick={() => {
                handleUser(data, 1);
                setIsloading1(true);
              }}
              type="button"
              className="btn rounded-pill btn-primary"
              disabled={isloading1}
            >
              {isloading1 ? "Accpting..." : "Accept"}
            </button>
            <button
              onClick={() => {
                handleUser(data, 2);
                setIsloading2(true);
              }}
              type="button"
              className={`btn rounded-pill btn-outline-danger`}
              disabled={isloading2}
            >
              {isloading2 ? "Rejecting..." : "Reject"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FollowRequestMemberCard;
